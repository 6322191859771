import React from 'react';

/**
 * Images/SVG
 */
import Background from '../../images/inner-page.png'
import { CanonLogo } from '../../components/canon-logo/canon-logo';
import Css from '../../images/css.png';
import Html from '../../images/html.png';
import Js from '../../images/js.png';
import Cps1 from '../../images/cpsImg1.png';
import Cps2 from '../../images/cpsImg2.png';
import Cps3 from '../../images/cpsImg3.png';

/**
 * Components
 */
import SEO from '../../components/seo/seo';
import Layout from '../../components/layout/layout';
import Banner from '../../components/default-banner/default-banner';
import Company from 
  '../../components/sub-page-client-company/sub-page-client-company';
// import Testimonial from 
//   '../../components/sub-page-client-testimonial/sub-page-client-testimonial';
import SeeMore from 
  '../../components/sub-page-client-see-more/sub-page-client-see-more';
import Brief from 
  '../../components/sub-page-client-brief/sub-page-client-brief';
import Challenge from 
  '../../components/sub-page-client-challenge/sub-page-client-challenge';

export default () => {

  /**
   * Banner
   */
  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'CPS',
    mainText: 'Canon Professional Services'
  };

  /**
   * Company data
   */
  const descriptionData = {
    link: 'https://www.canon-europe.com/pro/canon-professional-services/',
    logo: <CanonLogo />,
    titleDescription: 
      'CANON CPS – Upgrading Customers’ Portal to deliver priority support & operational efficiency',
    description: 
      'Canon professional services provide canon customers the opportunity to tell their exciting stories better and become full time imaging professionals with priority support. These include exclusive phone and email support, equipment loans and repairs, on-site support, discounts for events and special offers.',
    tech:[
      <img alt="html logo" className="html" src={Html}/>,
      <img alt="css logo" className="css" src={Css}/>,
      <img alt="js logo" className="js" src={Js}/>
    ]
  };

  /**
   * Brief Data
   */
  const briefData = {
    description: 
      'Canon CPS approached our company with a need for a marketing website and total revamp of its portal, so they could provide priority support to all canon customers.',
    images: [
      <img alt="cps3" src={Cps3} />,
      <img alt="cps2" src={Cps2} />
    ],
    scope: [
      'Marketing/landing page', 
      'New kit registration portal for canon customers'
    ]
  };

  /**
   * Challenge Data
   */
  const challengeData = {
    challenge: 
      'The portal service should operate on a three-tier system that is responsive and custom to the needs of professional photographers. Each level of CPS membership will be automatically assigned based on the type of kit registered with CPS.',
    solution: 
      'We created a portal that is available 24 hours a day, 7 days a week. This interface that we develop in cooperation with canon with the main objective of serving all the users and clients.',
    solutionPoint: false,
    outcome: false,
    outcomePoint: [
      'Over 80,000 registered users',
      '40% reduction in operating and maintenance costs',
      'Decreased delay time from 2 days to one day',
      '30% improvement in processing time'
    ],
    image: <img alt="cps1" src={Cps1} />
  };

  /**
   * Testemonial data
   */
  // const testimonialData = {
  //   quote: 
  //     'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
  //   name: 'Fred',
  //   position: 'full-stack'
  // };

  /**
   * See more footer
   */
  const seeMoreData = {
    links: ['pro','jll','usimoney']
  };

  return (
    <Layout>
      <SEO 
        title="Clients" 
        keywords={[`digital`, `agency`, `technology`, `Radium`, `Client`, `cps`]} 
      />
      <Banner {...innerBannerData} />
      <Company {...descriptionData} />
      <Brief {...briefData} />
      <Challenge {...challengeData}/>
      <SeeMore {...seeMoreData} />
    </Layout>
  )
};
